import axios from "axios";
import { useState, useEffect } from "react";
import { validateEmail, validateNumber } from "./services";

export default function useAppointment() {
  const [progress, setProgress] = useState(30);
  const [saving, setSaving] = useState(false);
  const [loader, setLoader] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [meeting, setMeeting] = useState(null);
  const [state, setState] = useState({
    datetime: "",
    appointmentTypeID: "",
    firstName: "",
    lastName: "",
    phone: "",
    timezone: "America/Los_Angeles",
    email: "",
    notes: "",
  });
  const [message, setMessage] = useState("");
  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window?.gtag("event", "acuity_modal", {
        event_category: "acuity_modal",
        event_label: "scheduler",
      });
    }
  }, []);

  const saveAppointment = async () => {
    const d = {
      ...state,
      notes: `${state.notes} -> Appointment comes from ${window.location.origin}`,
    };
    setSaving(true);
    try {
      await axios(`${process.env.GATSBY_ACUITY_ENDPOINT}/appointments`, {
        method: "post",
        data: d,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const meetingObj = {
        duration: selectedType.duration,
        topic: selectedType.name,
        start_time: state.datetime,
      };
      const meetingResponse = await axios(
        `${process.env.GATSBY_ZOOM_ENDPOINT}/create-meeting`,
        {
          method: "post",
          data: meetingObj,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (meetingResponse.status === 201) {
        setMeeting(meetingResponse?.data?.data);
      }

      setSaving(false);
      setProgress(100);
      if (typeof window !== "undefined" && window.gtag) {
        window?.gtag("event", "Appointed", {
          event_category: "acuity_modal",
          event_label: "Appointment Done",
        });
      }
    } catch (err) {
      alert("Something went wrong!");
      setSaving(false);
    }
  };

  const handleProgress = (val) => {
    const {
      datetime,
      appointmentTypeID,
      timezone,
      firstName,
      lastName,
      email,
      phone,
    } = state;

    if (val === 30) {
      setProgress(val);
    } else if (datetime && appointmentTypeID && timezone) {
      if (val === 70) {
        setProgress(val);
      } else {
        if (!firstName || !lastName) {
          setMessage("Fields are required");
        } else if (!validateEmail(email)) {
          setMessage("Invalid email address");
        } else if (!validateNumber(phone)) {
          setMessage("Invalid phone number");
        } else {
          // return true; when payment active uncomment this
          saveAppointment();
          // when payment active comment above line
        }
      }
    }
  };

  useEffect(() => {
    let timer;
    if (appointments.length > 0) {
      const elem1 = document.getElementById("step1");
      const elem2 = document.getElementById("step2");
      const elem3 = document.getElementById("step3");
      if (progress <= 30) {
        elem1.classList.remove("hidden");
        elem1.classList.add("show");

        elem2.classList.add("hidden");
        elem2.classList.remove("show");

        elem3.classList.add("hidden");
        elem3.classList.remove("show");
      } else if (progress <= 70) {
        elem1.classList.remove("show");
        elem1.classList.add("hidden");

        elem2.classList.add("show");
        elem2.classList.remove("hidden");

        elem3.classList.remove("show");
        elem3.classList.add("hidden");
      } else {
        elem1.classList.remove("show");
        elem1.classList.add("hidden");

        elem2.classList.remove("show");
        elem2.classList.add("hidden");

        elem3.classList.remove("hidden");
        elem3.classList.add("show");
      }
      timer = setTimeout(() => {
        if (progress <= 30) {
          elem1.style.display = "block";
          elem2.style.display = "none";
          elem3.style.display = "none";
        } else if (progress <= 70) {
          elem1.style.display = "none";
          elem2.style.display = "flex";
          elem3.style.display = "none";
        } else {
          elem1.style.display = "none";
          elem2.style.display = "none";
          elem3.style.display = "flex";
        }
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [progress, appointments]);

  const getAppointments = async () => {
    try {
      const result = await axios.get(
        `${process.env.GATSBY_ACUITY_ENDPOINT}/appointment-types`
      );
      setState({ ...state, appointmentTypeID: result.data[0]?.id });
      setSelectedType(result.data[0]);
      setAppointments(result.data);
      setLoader(false);
    } catch (err) {
      setAppointments([]);
      setLoader(false);
    }
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    setState({
      ...state,
      [name]: evt.target.value,
    });
    setMessage("");
    if (name === "appointmentTypeID") {
      const selected = appointments.filter(
        (i) => i.id === Number(evt.target.value)
      );
      setSelectedType(selected[0]);
    }
  };

  useEffect(() => {
    getAppointments();
  }, []);

  return {
    loader,
    progress,
    appointments,
    state,
    saving,
    selectedType,
    message,
    meeting,
    handleProgress,
    setState,
    handleChange,
    setMessage,
    setSaving,
    saveAppointment,
  };
}
