import React from "react";

export default function StepsBar({ handleProgress, progress }) {
  return (
    <div className="ap-steps">
      <div className="progress-bar">
        <div style={{ width: `${progress}%` }} />
      </div>
      <div className="step-items">
        <div className={`label ${progress >= 30 ? "label-active" : ""} `}>
          <div onClick={() => handleProgress(30)}>01</div>
          <span>Choose Appointment</span>
        </div>

        <div className={`label ${progress >= 70 ? "label-active" : ""}`}>
          <div onClick={() => handleProgress(70)}>02</div>
          <span>Your Info</span>
        </div>

        <div className={`label ${progress >= 90 ? "label-active" : ""}`}>
          <div>03</div>
          <span>Confirmation</span>
        </div>
      </div>
    </div>
  );
}
