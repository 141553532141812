import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import FormPopup from "../../common/FormPopup";

const BlogItem = ({ emailPlaceholder }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityBlog(limit: 2, sort: { _createdAt: DESC }) {
        nodes {
          blogTitle
          slug {
            current
          }
          thumbnailImage {
            alt
            image {
              asset {
                gatsbyImageData(
                  formats: WEBP
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
      sanityFormPopup(id: { eq: "-448f2eb4-3cbb-5316-8372-b0593e46f387" }) {
        id
        popupTitle
        successMsg
        buttonText
        linkType
        slug {
          current
        }
        url
        sectionId
      }
    }
  `);
  const blogs = data.allSanityBlog.nodes;
  let email = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const d = {
      data: {
        email: email.current.value,
        website: window.location.origin,
        date: new Date().toUTCString(),
      },
      sheetID: 7,
    };

    fetch("https://leads.civsav.com/template/contact", {
      method: "POST",
      body: JSON.stringify(d),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then(() => {
        email.current.value = "";
        setShowModal(true);
        setLoading(false);
      })
      .catch(() => {
        alert("Something went wrong! Please try again");
        setLoading(false);
      });
  };
  return (
    <div className="col lg:col-lg-4">
      <div className="mb-[50px] lg:mb-0">
        {/* Title */}
        <div>
          <h5 className="mb-[30px]">Recent News</h5>
        </div>
        {/* Adresses */}
        <ul className="mb-4">
          {blogs.length > 0 &&
            blogs.map((blog, index) => (
              <li key={index}>
                <Link
                  to={`/blog/${blog.slug.current}`}
                  className="flex mb-[25px]"
                >
                  {/* Icon */}
                  <div className="w-full max-w-[100px] mr-[15px]">
                    <GatsbyImage
                      image={blog.thumbnailImage.image.asset.gatsbyImageData}
                      alt={blog.thumbnailImage.alt}
                      className="max-w-[92px] aspect-video"
                      objectFit="cover"
                    />
                  </div>

                  {/* Content */}
                  <div>
                    <p className="text-[13px] text-[#a4a7b1] leading-6 font-light">
                      {blog.blogTitle}
                    </p>
                    {/* <span className="text-xs text-[#12c2e9]">14 sep 2022</span> */}
                  </div>
                </Link>
              </li>
            ))}
          <li>
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
              className="relative w-full"
            >
              <input
                type="email"
                name="email"
                id="email"
                ref={email}
                required
                placeholder={emailPlaceholder}
                className="w-full p-[15px] rounded-full border border-solid border-white/5 bg-transparent text-white text-[13px]
                placeholder:capitalize"
              />
              <button
                role="button"
                disabled={loading}
                type="submit"
                aria-label="Submit"
                className="absolute top-0 right-0 w-[50px] h-[50px] leading-[50px] text-center rounded-full bg-[#12c2e9] text-black
                flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 -rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </button>
            </form>
          </li>
        </ul>
      </div>
      <FormPopup
        onClose={() => setShowModal(false)}
        value={showModal}
        data={data.sanityFormPopup}
      />
    </div>
  );
};

export default BlogItem;
