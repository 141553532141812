import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { createEvent } from "ics";
var toArray = require("dayjs/plugin/toArray");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(toArray);

export default function Step3({ state, appointments, meeting }) {
  const [typeName, setTypeName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (state.appointmentTypeID) {
      const filter = appointments.filter(
        (i) => i.id === Number(state.appointmentTypeID)
      );
      setTypeName(filter[0].name);
    }
    if (state.datetime) {
      setDate(
        dayjs(state.datetime).tz(state.timezone).format("dddd, MMMM D, YYYY")
      );
      setTime(dayjs(state.datetime).tz(state.timezone).format("hh:mm A"));
    }
  }, [state]);

  const event = {
    start: dayjs(state.datetime).tz(state.timezone).toArray().slice(0, 5),
    duration: { minutes: 25 },
    title: typeName,
    description: `Client time zone: ${state.timezone}\nName: ${state.firstName} ${state.lastName}\nPhone: ${state.phone}\nEmail: ${state.email}\n\nLocation\n============\nZoom or Phone
    Call \n\nMeeting Link: ${meeting?.meeting_url}\n\nMeeting Password: ${meeting?.password}`,
    location: "Zoom or Phone Call",
    status: "CONFIRMED",
  };

  const downloadICSFile = async () => {
    const filename = "TJ.ics";
    const file = await new Promise((resolve, reject) => {
      createEvent(event, (error, value) => {
        if (error) {
          reject(error);
        }

        resolve(new File([value], filename, { type: "plain/text" }));
      });
    });
    const url = URL.createObjectURL(file);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  };

  const googleCalendar = () => {
    const endTime = dayjs(state.datetime).tz(state.timezone).add(25, "minute");

    const google = `http://www.google.com/calendar/event?action=TEMPLATE&text=${typeName}&dates=${dayjs(
      state.datetime
    )
      .tz(state.timezone)
      .format("YYYYMMDDTHHmmss")}/${endTime.format(
      "YYYYMMDDTHHmmss"
    )}&details=Client time zone: ${state.timezone}\nName: ${state.firstName} ${
      state.lastName
    }\nPhone: ${state.phone}\nEmail: ${state.email}\n\nMeeting Link: ${
      meeting?.meeting_url
    }\nMeeting Password: ${
      meeting?.password
    }\n\nLocation\n============\nZoom or Phone
      Call&location=Zoom or Phone Call`;
    const anchor = document.createElement("a");
    anchor.href = google;
    anchor.setAttribute("target", "_blank");

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(google);
  };

  return (
    <>
      <h1 className="common-heading">{typeName}</h1>
      <h2>{date}</h2>
      <h2>{time}</h2>
      <h3 className="text-gradient common-heading">Zoom or Phone Call</h3>
      <div className="alert-message common-heading">
        Changes to this appointment can no longer be made online.
      </div>
      <div className="btn-group">
        <div>
          <button id="btn2" onClick={downloadICSFile}>
            Add to iCal/Outlook
          </button>
        </div>
        <button id="btn3" onClick={googleCalendar}>
          Add To Google
        </button>
      </div>
    </>
  );
}
