import React, { useEffect, useRef } from "react";

const MouseEffect = ({ color }) => {
  const cursorInnerRef = useRef(null);
  const cursorOuterRef = useRef(null);

  useEffect(() => {
    let n,
      i = 0,
      o = false;

    const handleMouseMove = (event) => {
      if (!o) {
        cursorOuterRef.current.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
      }
      cursorInnerRef.current.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
      n = event.clientY;
      i = event.clientX;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const cursorPointer = document.querySelector(".cursor-pointer");

    if (cursorPointer) {
      cursorPointer.addEventListener("mouseenter", () => {
        cursorInnerRef.current.classList.add("cursor-hover");
        cursorOuterRef.current.classList.add("cursor-hover");
      });

      cursorPointer.addEventListener("mouseleave", () => {
        cursorInnerRef.current.classList.remove("cursor-hover");
        cursorOuterRef.current.classList.remove("cursor-hover");
      });

      cursorInnerRef.current.style.visibility = "visible";
      cursorOuterRef.current.style.visibility = "visible";
    }
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll("a");

    links.forEach((link) => {
      link.addEventListener("mouseenter", () => {
        cursorInnerRef.current.classList.add("cursor-hover");
        cursorOuterRef.current.classList.add("cursor-hover");
      });

      link.addEventListener("mouseleave", () => {
        cursorInnerRef.current.classList.remove("cursor-hover");
        cursorOuterRef.current.classList.remove("cursor-hover");
      });
    });

    cursorInnerRef.current.style.visibility = "visible";
    cursorOuterRef.current.style.visibility = "visible";
  }, []);

  return (
    <>
      <div
        className="mouse-cursor cursor-inner"
        style={{
          backgroundColor: color,
        }}
        ref={cursorInnerRef}
      />
      <div
        className="mouse-cursor cursor-outer"
        style={{
          borderColor: color,
        }}
        ref={cursorOuterRef}
      />
    </>
  );
};

export default MouseEffect;
