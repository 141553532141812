import React from "react";

export default function Button({
  text = "Confirm",
  onClick = null,
  disable = false,
}) {
  return (
    <div className="ap-button">
      <button className="common-heading" onClick={onClick} disabled={disable}>
        {text}
      </button>
    </div>
  );
}
