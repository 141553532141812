exports.components = {
  "component---src-landing-page-index-jsx": () => import("./../../../src/LandingPage/index.jsx" /* webpackChunkName: "component---src-landing-page-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-leads-calculator-jsx": () => import("./../../../src/pages/leads-calculator.jsx" /* webpackChunkName: "component---src-pages-leads-calculator-jsx" */),
  "component---src-template-blog-detail-jsx": () => import("./../../../src/template/blog-detail.jsx" /* webpackChunkName: "component---src-template-blog-detail-jsx" */)
}

