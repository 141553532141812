import React from "react";

export default function Loader() {
  return (
    <div className="ap-loader">
      <div className="ap-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
