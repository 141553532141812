import { graphql, useStaticQuery } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import MouseEffect from "../../utils/MouseEffect";
import ScrollToTop from "../../utils/ScrollToTop";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ExitIntent from "../../utils/ExitIntent";
import Popup from "../common/Popup";
import Appointment from "../Appointment";

const Layout = ({ children }) => {
  const { sanitySitesettings } = useStaticQuery(graphql`
    {
      sanitySitesettings(id: { eq: "-dd62fdff-2b37-52e2-85dd-f99a5972f82f" }) {
        cursor {
          enable
          color {
            hex
          }
        }
        scroll {
          hex
        }
        enableExitIntent
        popupType
        simplePopup {
          popupDelay
          title
          description
          popupImage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                placeholder: BLURRED
              )
              title
            }
          }
          buttonText
          linkType
          slug {
            current
          }
          url
          sectionId
        }
        _rawCc
      }
    }
  `);

  const [showPopup, setShowPopup] = useState(false);

  function isBottom(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById("footer");
    if (isBottom(wrappedElement)) {
      ExitIntent({
        threshold: 30,
        eventThrottle: 100,
        onExitIntent: () => {
          const exitIntentSession = sessionStorage.getItem("@exitIntent");
          if (!exitIntentSession) {
            sessionStorage.setItem("@exitIntent", "done");
            setShowPopup(true);
          }
        },
      });
      document.removeEventListener("scroll", trackScrolling);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", trackScrolling);

    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  return (
    <div className="w-full relative">
      {sanitySitesettings.cursor.enable && (
        <MouseEffect color={sanitySitesettings.cursor.color.hex} />
      )}
      <ScrollToTop color={sanitySitesettings?.scroll.hex} />
      <Navbar />
      {children}
      <Footer cc={sanitySitesettings?._rawCc} />

      {showPopup && sanitySitesettings.enableExitIntent && (
        <Fragment>
          {sanitySitesettings.popupType === "simplePopup" && (
            <Popup
              onClose={() => setShowPopup(false)}
              value={showPopup}
              data={sanitySitesettings.simplePopup}
            />
          )}
          {sanitySitesettings.popupType === "appointmentPopup" && (
            <Appointment closeModal={() => setShowPopup(false)} />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Layout;
