import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import Button from "../Button";
import Loader from "../Loader";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
export default function Step1({
  onClick,
  handleChange,
  state,
  setState,
  appointments,
  // selectedType,
}) {
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const today = dayjs().format("YYYY-MM");

  const getDates = async () => {
    setLoader(true);
    setLoader2(true);
    setSelectedDate("");
    try {
      const result = await axios.get(
        `${process.env.GATSBY_ACUITY_ENDPOINT}/availability/dates?appointmentTypeID=${state.appointmentTypeID}&month=${today}&timezone=${state.timezone}`
      );

      setAvailableDates(result.data);
      setSelectedDate(result.data[0]?.date);
      setLoader(false);
    } catch (err) {
      setAvailableDates([]);
      setLoader(false);
      setLoader2(false);
    }
  };

  const getTimes = async () => {
    setLoader2(true);
    try {
      const result = await axios.get(
        `${process.env.GATSBY_ACUITY_ENDPOINT}/availability/times?appointmentTypeID=${state.appointmentTypeID}&date=${selectedDate}&timezone=${state.timezone}`
      );

      setAvailableTimes(result.data);
      setSelectedTime(result.data[0].time);
      setState({
        ...state,
        datetime: dayjs(result.data[0].time).tz("America/Los_Angeles").format(),
      });
      setLoader2(false);
    } catch (err) {
      setAvailableDates([]);
      setLoader2(false);
    }
  };

  useEffect(() => {
    if (state.appointmentTypeID) {
      getDates();
    }
  }, [state.timezone, state.appointmentTypeID]);

  useEffect(() => {
    if (selectedDate) {
      getTimes();
    }
  }, [selectedDate]);

  const handleDate = (time) => {
    setState({
      ...state,
      datetime: dayjs(time).tz("America/Los_Angeles").format(),
    });
    setSelectedTime(time);
  };

  return (
    <>
      <div className="timezone">
        <p className="common-heading">Appointment Type</p>
        <label className="zone-select">
          <select
            name="appointmentTypeID"
            id="timezone"
            className="common-heading"
            value={state.appointmentTypeID}
            onChange={handleChange}
          >
            {/* <option value={""}>Choose appointment type</option> */}
            {/* <option value={"17633252"}>Headless Consultation</option> */}

            {appointments?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L5 5L1 1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </label>
        {/* {selectedType?.price && parseInt(selectedType.price) !== 0 && (
          <p className="text-[10px] text-red-400 mt-2">
            Note: The charges of appointment is ${selectedType.price}
          </p>
        )} */}
        <br />
        <p className="common-heading">YOUR TIME ZONE</p>
        <label className="zone-select">
          <select
            name="timezone"
            id="timezone"
            className="common-heading"
            value={state.timezone}
            onChange={handleChange}
          >
            <optgroup label="United States">
              <option value="Pacific/Honolulu">(GMT-10:00) Hawaii Time</option>
              <option value="America/Anchorage">(GMT-9:00) Alaska Time</option>
              <option value="America/Los_Angeles" selected="">
                (GMT-8:00) Pacific Time
              </option>
              <option value="America/Denver">(GMT-7:00) Mountain Time</option>
              <option value="America/Phoenix">(GMT-7:00) Arizona Time</option>
              <option value="America/Chicago">(GMT-6:00) Central Time</option>
              <option value="America/New_York">(GMT-5:00) Eastern Time</option>
            </optgroup>
            <option disabled=""></option>
            <option value="Pacific/Apia">(GMT+13:00) Apia</option>
            <option value="Pacific/Midway">(GMT-11:00) Midway</option>
            <option value="Pacific/Niue">(GMT-11:00) Niue</option>
            <option value="Pacific/Pago_Pago">(GMT-11:00) Pago Pago</option>
            <option value="Pacific/Fakaofo">(GMT+13:00) Fakaofo</option>
            <option value="Pacific/Johnston">(GMT-10:00) Johnston</option>
            <option value="Pacific/Rarotonga">(GMT-10:00) Rarotonga</option>
            <option value="Pacific/Tahiti">(GMT-10:00) Tahiti</option>
            <option value="Pacific/Marquesas">(GMT-9:30) Marquesas</option>
            <option value="Pacific/Gambier">(GMT-9:00) Gambier</option>
            <option value="America/Tijuana">
              (GMT-8:00) Pacific Time - Tijuana
            </option>
            <option value="America/Vancouver">
              (GMT-8:00) Pacific Time - Vancouver
            </option>
            <option value="Pacific/Pitcairn">(GMT-8:00) Pitcairn</option>
            <option value="America/Dawson_Creek">
              (GMT-7:00) Mountain Time - Dawson Creek
            </option>
            <option value="America/Edmonton">
              (GMT-7:00) Mountain Time - Edmonton
            </option>
            <option value="America/Hermosillo">
              (GMT-7:00) Mountain Time - Hermosillo
            </option>
            <option value="America/Mazatlan">
              (GMT-7:00) Mountain Time - Chihuahua, Mazatlan
            </option>
            <option value="America/Whitehorse">
              (GMT-7:00) Mountain Time - Whitehorse
            </option>
            <option value="America/Yellowknife">
              (GMT-7:00) Mountain Time - Yellowknife
            </option>
            <option value="America/Belize">(GMT-6:00) Belize</option>
            <option value="America/Costa_Rica">(GMT-6:00) Costa Rica</option>
            <option value="America/El_Salvador">(GMT-6:00) El Salvador</option>
            <option value="America/Guatemala">(GMT-6:00) Guatemala</option>
            <option value="America/Managua">(GMT-6:00) Managua</option>
            <option value="America/Mexico_City">
              (GMT-6:00) Central Time - Mexico City
            </option>
            <option value="America/Regina">
              (GMT-6:00) Central Time - Regina
            </option>
            <option value="America/Tegucigalpa">
              (GMT-6:00) Central Time - Tegucigalpa (no DST)
            </option>
            <option value="America/Winnipeg">
              (GMT-6:00) Central Time - Winnipeg
            </option>
            <option value="Pacific/Easter">(GMT-5:00) Easter Island</option>
            <option value="Pacific/Galapagos">(GMT-6:00) Galapagos</option>
            <option value="America/Bogota">(GMT-5:00) Bogota</option>
            <option value="America/Cayman">(GMT-5:00) Cayman</option>
            <option value="America/Grand_Turk">(GMT-5:00) Grand Turk</option>
            <option value="America/Guayaquil">(GMT-5:00) Guayaquil</option>
            <option value="America/Havana">(GMT-5:00) Havana</option>
            <option value="America/Iqaluit">
              (GMT-5:00) Eastern Time - Iqaluit
            </option>
            <option value="America/Jamaica">(GMT-5:00) Jamaica</option>
            <option value="America/Lima">(GMT-5:00) Lima</option>
            <option value="America/Cancun">
              (GMT-5:00) Cancun - Eastern Time - Quintana Roo
            </option>
            <option value="America/Nassau">(GMT-5:00) Nassau</option>
            <option value="America/Panama">(GMT-5:00) Panama</option>
            <option value="America/Port-au-Prince">
              (GMT-5:00) Port-au-Prince
            </option>
            <option value="America/Toronto">
              (GMT-5:00) Eastern Time - Toronto
            </option>
            <option value="America/Caracas">(GMT-4:00) Caracas</option>
            <option value="America/Anguilla">(GMT-4:00) Anguilla</option>
            <option value="America/Antigua">(GMT-4:00) Antigua</option>
            <option value="America/Aruba">(GMT-4:00) Aruba</option>
            <option value="America/Asuncion">(GMT-3:00) Asuncion</option>
            <option value="America/Barbados">(GMT-4:00) Barbados</option>
            <option value="America/Boa_Vista">(GMT-4:00) Boa Vista</option>
            <option value="America/Campo_Grande">
              (GMT-4:00) Campo Grande
            </option>
            <option value="America/Cuiaba">(GMT-4:00) Cuiaba</option>
            <option value="America/Curacao">(GMT-4:00) Curacao</option>
            <option value="America/Dominica">(GMT-4:00) Dominica</option>
            <option value="America/Grenada">(GMT-4:00) Grenada</option>
            <option value="America/Guadeloupe">(GMT-4:00) Guadeloupe</option>
            <option value="America/Guyana">(GMT-4:00) Guyana</option>
            <option value="America/Halifax">
              (GMT-4:00) Atlantic Time - Halifax
            </option>
            <option value="America/La_Paz">(GMT-4:00) La Paz</option>
            <option value="America/Manaus">(GMT-4:00) Manaus</option>
            <option value="America/Martinique">(GMT-4:00) Martinique</option>
            <option value="America/Montserrat">(GMT-4:00) Montserrat</option>
            <option value="America/Port_of_Spain">
              (GMT-4:00) Port of Spain
            </option>
            <option value="America/Porto_Velho">(GMT-4:00) Porto Velho</option>
            <option value="America/Puerto_Rico">(GMT-4:00) Puerto Rico</option>
            <option value="America/Rio_Branco">(GMT-5:00) Rio Branco</option>
            <option value="America/Santiago">(GMT-3:00) Santiago</option>
            <option value="America/Santo_Domingo">
              (GMT-4:00) Santo Domingo
            </option>
            <option value="America/St_Kitts">(GMT-4:00) St. Kitts</option>
            <option value="America/St_Lucia">(GMT-4:00) St. Lucia</option>
            <option value="America/St_Thomas">(GMT-4:00) St. Thomas</option>
            <option value="America/St_Vincent">(GMT-4:00) St. Vincent</option>
            <option value="America/Thule">(GMT-4:00) Thule</option>
            <option value="America/Tortola">(GMT-4:00) Tortola</option>
            <option value="Antarctica/Palmer">(GMT-3:00) Palmer</option>
            <option value="Atlantic/Bermuda">(GMT-4:00) Bermuda</option>
            <option value="Atlantic/Stanley">(GMT-3:00) Stanley</option>
            <option value="America/St_Johns">
              (GMT-3:30) Newfoundland Time - St. Johns
            </option>
            <option value="America/Araguaina">(GMT-3:00) Araguaina</option>
            <option value="America/Argentina/Buenos_Aires">
              (GMT-3:00) Buenos Aires
            </option>
            <option value="America/Bahia">(GMT-3:00) Salvador</option>
            <option value="America/Belem">(GMT-3:00) Belem</option>
            <option value="America/Cayenne">(GMT-3:00) Cayenne</option>
            <option value="America/Fortaleza">(GMT-3:00) Fortaleza</option>
            <option value="America/Godthab">(GMT-3:00) Godthab</option>
            <option value="America/Maceio">(GMT-3:00) Maceio</option>
            <option value="America/Miquelon">(GMT-3:00) Miquelon</option>
            <option value="America/Montevideo">(GMT-3:00) Montevideo</option>
            <option value="America/Paramaribo">(GMT-3:00) Paramaribo</option>
            <option value="America/Recife">(GMT-3:00) Recife</option>
            <option value="America/Sao_Paulo">(GMT-3:00) Sao Paulo</option>
            <option value="Antarctica/Rothera">(GMT-3:00) Rothera</option>
            <option value="America/Noronha">(GMT-2:00) Noronha</option>
            <option value="Atlantic/South_Georgia">
              (GMT-2:00) South Georgia
            </option>
            <option value="America/Scoresbysund">
              (GMT-1:00) Scoresbysund
            </option>
            <option value="Atlantic/Azores">(GMT-1:00) Azores</option>
            <option value="Atlantic/Cape_Verde">(GMT-1:00) Cape Verde</option>
            <option value="Africa/Abidjan">(GMT+0:00) Abidjan</option>
            <option value="Africa/Accra">(GMT+0:00) Accra</option>
            <option value="Africa/Bamako">(GMT+0:00) Bamako</option>
            <option value="Africa/Banjul">(GMT+0:00) Banjul</option>
            <option value="Africa/Bissau">(GMT+0:00) Bissau</option>
            <option value="Africa/Casablanca">(GMT+1:00) Casablanca</option>
            <option value="Africa/Conakry">(GMT+0:00) Conakry</option>
            <option value="Africa/Dakar">(GMT+0:00) Dakar</option>
            <option value="Africa/El_Aaiun">(GMT+1:00) El Aaiun</option>
            <option value="Africa/Freetown">(GMT+0:00) Freetown</option>
            <option value="Africa/Lome">(GMT+0:00) Lome</option>
            <option value="Africa/Monrovia">(GMT+0:00) Monrovia</option>
            <option value="Africa/Nouakchott">(GMT+0:00) Nouakchott</option>
            <option value="Africa/Ouagadougou">(GMT+0:00) Ouagadougou</option>
            <option value="Africa/Sao_Tome">(GMT+0:00) Sao Tome</option>
            <option value="America/Danmarkshavn">
              (GMT+0:00) Danmarkshavn
            </option>
            <option value="Atlantic/Canary">(GMT+0:00) Canary Islands</option>
            <option value="Atlantic/Faroe">(GMT+0:00) Faeroe</option>
            <option value="Atlantic/Reykjavik">(GMT+0:00) Reykjavik</option>
            <option value="Atlantic/St_Helena">(GMT+0:00) St Helena</option>
            <option value="Etc/GMT">(GMT+0:00) GMT (no daylight saving)</option>
            <option value="Europe/Dublin">(GMT+0:00) Dublin</option>
            <option value="Europe/Lisbon">(GMT+0:00) Lisbon</option>
            <option value="Europe/London">(GMT+0:00) London</option>
            <option value="Africa/Algiers">(GMT+1:00) Algiers</option>
            <option value="Africa/Bangui">(GMT+1:00) Bangui</option>
            <option value="Africa/Brazzaville">(GMT+1:00) Brazzaville</option>
            <option value="Africa/Ceuta">(GMT+1:00) Ceuta</option>
            <option value="Africa/Douala">(GMT+1:00) Douala</option>
            <option value="Africa/Kinshasa">(GMT+1:00) Kinshasa</option>
            <option value="Africa/Lagos">(GMT+1:00) Lagos</option>
            <option value="Africa/Libreville">(GMT+1:00) Libreville</option>
            <option value="Africa/Luanda">(GMT+1:00) Luanda</option>
            <option value="Africa/Malabo">(GMT+1:00) Malabo</option>
            <option value="Africa/Ndjamena">(GMT+1:00) Ndjamena</option>
            <option value="Africa/Niamey">(GMT+1:00) Niamey</option>
            <option value="Africa/Porto-Novo">(GMT+1:00) Porto-Novo</option>
            <option value="Africa/Tunis">(GMT+1:00) Tunis</option>
            <option value="Africa/Windhoek">(GMT+2:00) Windhoek</option>
            <option value="Europe/Amsterdam">(GMT+1:00) Amsterdam</option>
            <option value="Europe/Andorra">(GMT+1:00) Andorra</option>
            <option value="Europe/Belgrade">
              (GMT+1:00) Central European Time (Europe/Belgrade)
            </option>
            <option value="Europe/Berlin">(GMT+1:00) Berlin</option>
            <option value="Europe/Brussels">(GMT+1:00) Brussels</option>
            <option value="Europe/Budapest">(GMT+1:00) Budapest</option>
            <option value="Europe/Copenhagen">(GMT+1:00) Copenhagen</option>
            <option value="Europe/Gibraltar">(GMT+1:00) Gibraltar</option>
            <option value="Europe/Luxembourg">(GMT+1:00) Luxembourg</option>
            <option value="Europe/Madrid">(GMT+1:00) Madrid</option>
            <option value="Europe/Malta">(GMT+1:00) Malta</option>
            <option value="Europe/Monaco">(GMT+1:00) Monaco</option>
            <option value="Europe/Oslo">(GMT+1:00) Oslo</option>
            <option value="Europe/Paris">(GMT+1:00) Paris</option>
            <option value="Europe/Prague">
              (GMT+1:00) Central European Time (Europe/Prague)
            </option>
            <option value="Europe/Rome">(GMT+1:00) Rome</option>
            <option value="Europe/Stockholm">(GMT+1:00) Stockholm</option>
            <option value="Europe/Tirane">(GMT+1:00) Tirane</option>
            <option value="Europe/Vaduz">(GMT+1:00) Vaduz</option>
            <option value="Europe/Vienna">(GMT+1:00) Vienna</option>
            <option value="Europe/Warsaw">(GMT+1:00) Warsaw</option>
            <option value="Europe/Zurich">(GMT+1:00) Zurich</option>
            <option value="Africa/Blantyre">(GMT+2:00) Blantyre</option>
            <option value="Africa/Bujumbura">(GMT+2:00) Bujumbura</option>
            <option value="Africa/Cairo">(GMT+2:00) Cairo</option>
            <option value="Africa/Gaborone">(GMT+2:00) Gaborone</option>
            <option value="Africa/Harare">(GMT+2:00) Harare</option>
            <option value="Africa/Johannesburg">(GMT+2:00) Johannesburg</option>
            <option value="Africa/Kigali">(GMT+2:00) Kigali</option>
            <option value="Africa/Lubumbashi">(GMT+2:00) Lubumbashi</option>
            <option value="Africa/Lusaka">(GMT+2:00) Lusaka</option>
            <option value="Africa/Maputo">(GMT+2:00) Maputo</option>
            <option value="Africa/Maseru">(GMT+2:00) Maseru</option>
            <option value="Africa/Mbabane">(GMT+2:00) Mbabane</option>
            <option value="Africa/Tripoli">(GMT+2:00) Tripoli</option>
            <option value="Asia/Amman">(GMT+3:00) Amman</option>
            <option value="Asia/Beirut">(GMT+2:00) Beirut</option>
            <option value="Asia/Damascus">(GMT+3:00) Damascus</option>
            <option value="Asia/Gaza">(GMT+2:00) Gaza</option>
            <option value="Asia/Jerusalem">(GMT+2:00) Jerusalem</option>
            <option value="Asia/Nicosia">(GMT+2:00) Nicosia</option>
            <option value="Europe/Athens">(GMT+2:00) Athens</option>
            <option value="Europe/Bucharest">(GMT+2:00) Bucharest</option>
            <option value="Europe/Chisinau">(GMT+2:00) Chisinau</option>
            <option value="Europe/Helsinki">(GMT+2:00) Helsinki</option>
            <option value="Europe/Istanbul">(GMT+3:00) Istanbul</option>
            <option value="Europe/Kaliningrad">
              (GMT+2:00) Russia - Kaliningrad
            </option>
            <option value="Europe/Kiev">(GMT+2:00) Kyiv</option>
            <option value="Europe/Minsk">(GMT+3:00) Minsk</option>
            <option value="Europe/Riga">(GMT+2:00) Riga</option>
            <option value="Europe/Sofia">(GMT+2:00) Sofia</option>
            <option value="Europe/Tallinn">(GMT+2:00) Tallinn</option>
            <option value="Europe/Vilnius">(GMT+2:00) Vilnius</option>
            <option value="Africa/Addis_Ababa">(GMT+3:00) Addis Ababa</option>
            <option value="Africa/Asmara">(GMT+3:00) Asmera</option>
            <option value="Africa/Dar_es_Salaam">
              (GMT+3:00) Dar es Salaam
            </option>
            <option value="Africa/Djibouti">(GMT+3:00) Djibouti</option>
            <option value="Africa/Kampala">(GMT+3:00) Kampala</option>
            <option value="Africa/Khartoum">(GMT+2:00) Khartoum</option>
            <option value="Africa/Mogadishu">(GMT+3:00) Mogadishu</option>
            <option value="Africa/Nairobi">(GMT+3:00) Nairobi</option>
            <option value="Antarctica/Syowa">(GMT+3:00) Syowa</option>
            <option value="Asia/Aden">(GMT+3:00) Aden</option>
            <option value="Asia/Baghdad">(GMT+3:00) Baghdad</option>
            <option value="Asia/Bahrain">(GMT+3:00) Bahrain</option>
            <option value="Asia/Kuwait">(GMT+3:00) Kuwait</option>
            <option value="Asia/Qatar">(GMT+3:00) Qatar</option>
            <option value="Asia/Riyadh">(GMT+3:00) Riyadh</option>
            <option value="Europe/Moscow">(GMT+3:00) Russia - Moscow</option>
            <option value="Indian/Antananarivo">(GMT+3:00) Antananarivo</option>
            <option value="Indian/Comoro">(GMT+3:00) Comoro</option>
            <option value="Indian/Mayotte">(GMT+3:00) Mayotte</option>
            <option value="Asia/Tehran">(GMT+3:30) Tehran</option>
            <option value="Asia/Baku">(GMT+4:00) Baku</option>
            <option value="Asia/Dubai">(GMT+4:00) Dubai</option>
            <option value="Asia/Muscat">(GMT+4:00) Muscat</option>
            <option value="Asia/Tbilisi">(GMT+4:00) Tbilisi</option>
            <option value="Asia/Yerevan">(GMT+4:00) Yerevan</option>
            <option value="Europe/Samara">(GMT+4:00) Russia - Samara</option>
            <option value="Indian/Mahe">(GMT+4:00) Mahe</option>
            <option value="Indian/Mauritius">(GMT+4:00) Mauritius</option>
            <option value="Indian/Reunion">(GMT+4:00) Reunion</option>
            <option value="Asia/Kabul">(GMT+4:30) Kabul</option>
            <option value="Asia/Aqtau">(GMT+5:00) Aqtau</option>
            <option value="Asia/Aqtobe">(GMT+5:00) Aqtobe</option>
            <option value="Asia/Ashgabat">(GMT+5:00) Ashgabat</option>
            <option value="Asia/Dushanbe">(GMT+5:00) Dushanbe</option>
            <option value="Asia/Karachi">(GMT+5:00) Karachi</option>
            <option value="Asia/Tashkent">(GMT+5:00) Tashkent</option>
            <option value="Asia/Yekaterinburg">
              (GMT+5:00) Russia - Yekaterinburg
            </option>
            <option value="Indian/Kerguelen">(GMT+5:00) Kerguelen</option>
            <option value="Indian/Maldives">(GMT+5:00) Maldives</option>
            <option value="Asia/Kolkata">(GMT+5:30) India Standard Time</option>
            <option value="Asia/Colombo">(GMT+5:30) Colombo</option>
            <option value="Asia/Kathmandu">(GMT+5:45) Kathmandu</option>
            <option value="Antarctica/Mawson">(GMT+5:00) Mawson</option>
            <option value="Antarctica/Vostok">(GMT+6:00) Vostok</option>
            <option value="Asia/Almaty">(GMT+6:00) Almaty</option>
            <option value="Asia/Bishkek">(GMT+6:00) Bishkek</option>
            <option value="Asia/Dhaka">(GMT+6:00) Dhaka</option>
            <option value="Asia/Omsk">(GMT+6:00) Russia - Omsk</option>
            <option value="Asia/Novosibirsk">
              (GMT+7:00) Russia - Novosibirsk
            </option>
            <option value="Asia/Thimphu">(GMT+6:00) Thimphu</option>
            <option value="Indian/Chagos">(GMT+6:00) Chagos</option>
            <option value="Asia/Rangoon">(GMT+6:30) Rangoon</option>
            <option value="Indian/Cocos">(GMT+6:30) Cocos</option>
            <option value="Antarctica/Davis">(GMT+7:00) Davis</option>
            <option value="Asia/Bangkok">(GMT+7:00) Bangkok</option>
            <option value="Asia/Hovd">(GMT+7:00) Hovd</option>
            <option value="Asia/Jakarta">(GMT+7:00) Jakarta</option>
            <option value="Asia/Krasnoyarsk">
              (GMT+7:00) Russia - Krasnoyarsk
            </option>
            <option value="Asia/Phnom_Penh">(GMT+7:00) Phnom Penh</option>
            <option value="Asia/Ho_Chi_Minh">(GMT+7:00) Hanoi</option>
            <option value="Asia/Vientiane">(GMT+7:00) Vientiane</option>
            <option value="Indian/Christmas">(GMT+7:00) Christmas</option>
            <option value="Antarctica/Casey">(GMT+11:00) Casey</option>
            <option value="Asia/Brunei">(GMT+8:00) Brunei</option>
            <option value="Asia/Choibalsan">(GMT+8:00) Choibalsan</option>
            <option value="Asia/Hong_Kong">(GMT+8:00) Hong Kong</option>
            <option value="Asia/Irkutsk">(GMT+8:00) Russia - Irkutsk</option>
            <option value="Asia/Kuala_Lumpur">(GMT+8:00) Kuala Lumpur</option>
            <option value="Asia/Macau">(GMT+8:00) Macau</option>
            <option value="Asia/Makassar">(GMT+8:00) Makassar</option>
            <option value="Asia/Manila">(GMT+8:00) Manila</option>
            <option value="Asia/Shanghai">
              (GMT+8:00) China Time - Beijing
            </option>
            <option value="Asia/Singapore">(GMT+8:00) Singapore</option>
            <option value="Asia/Taipei">(GMT+8:00) Taipei</option>
            <option value="Asia/Ulaanbaatar">(GMT+8:00) Ulaanbaatar</option>
            <option value="Australia/Perth">
              (GMT+8:00) Western Time - Perth
            </option>
            <option value="Asia/Dili">(GMT+9:00) Dili</option>
            <option value="Asia/Jayapura">(GMT+9:00) Jayapura</option>
            <option value="Asia/Pyongyang">(GMT+9:00) Pyongyang</option>
            <option value="Asia/Seoul">(GMT+9:00) Seoul</option>
            <option value="Asia/Tokyo">(GMT+9:00) Tokyo</option>
            <option value="Asia/Yakutsk">(GMT+9:00) Russia - Yakutsk</option>
            <option value="Pacific/Palau">(GMT+9:00) Palau</option>
            <option value="Australia/Adelaide">
              (GMT+10:30) Central Time - Adelaide
            </option>
            <option value="Australia/Darwin">
              (GMT+9:30) Central Time - Darwin
            </option>
            <option value="Antarctica/DumontDUrville">
              (GMT+10:00) Dumont D'Urville
            </option>
            <option value="Asia/Vladivostok">
              (GMT+10:00) Russia - Yuzhno-Sakhalinsk
            </option>
            <option value="Australia/Brisbane">
              (GMT+10:00) Eastern Time - Brisbane
            </option>
            <option value="Australia/Hobart">
              (GMT+11:00) Eastern Time - Hobart
            </option>
            <option value="Australia/Sydney">
              (GMT+11:00) Eastern Time - Sydney
            </option>
            <option value="Australia/Melbourne">
              (GMT+11:00) Eastern Time - Melbourne
            </option>
            <option value="Pacific/Guam">(GMT+10:00) Guam</option>
            <option value="Pacific/Port_Moresby">
              (GMT+10:00) Port Moresby
            </option>
            <option value="Pacific/Saipan">(GMT+10:00) Saipan</option>
            <option value="Pacific/Chuuk">(GMT+10:00) Chuuk</option>
            <option value="Asia/Magadan">(GMT+11:00) Russia - Magadan</option>
            <option value="Pacific/Efate">(GMT+11:00) Efate</option>
            <option value="Pacific/Guadalcanal">(GMT+11:00) Guadalcanal</option>
            <option value="Pacific/Kosrae">(GMT+11:00) Kosrae</option>
            <option value="Pacific/Noumea">(GMT+11:00) Noumea</option>
            <option value="Pacific/Pohnpei">(GMT+11:00) Pohnpei</option>
            <option value="Pacific/Norfolk">(GMT+12:00) Norfolk</option>
            <option value="Asia/Kamchatka">
              (GMT+12:00) Russia - Petropavlovsk-Kamchatskiy
            </option>
            <option value="Pacific/Auckland">(GMT+13:00) Auckland</option>
            <option value="Pacific/Fiji">(GMT+12:00) Fiji</option>
            <option value="Pacific/Funafuti">(GMT+12:00) Funafuti</option>
            <option value="Pacific/Kwajalein">(GMT+12:00) Kwajalein</option>
            <option value="Pacific/Majuro">(GMT+12:00) Majuro</option>
            <option value="Pacific/Nauru">(GMT+12:00) Nauru</option>
            <option value="Pacific/Tarawa">(GMT+12:00) Tarawa</option>
            <option value="Pacific/Wake">(GMT+12:00) Wake</option>
            <option value="Pacific/Wallis">(GMT+12:00) Wallis</option>
            <option value="Pacific/Enderbury">(GMT+13:00) Enderbury</option>
            <option value="Pacific/Tongatapu">(GMT+13:00) Tongatapu</option>
            <option value="Pacific/Kiritimati">(GMT+14:00) Kiritimati</option>
          </select>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L5 5L1 1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </label>
      </div>
      {state.appointmentTypeID && (
        <>
          {loader ? (
            <Loader />
          ) : availableDates.length > 0 ? (
            <div className="dates">
              {availableDates.map((item, index) => {
                return (
                  <div
                    className={`d-item ${
                      selectedDate === item.date ? "d-active" : ""
                    }`}
                    key={index}
                    onClick={() => setSelectedDate(item.date)}
                  >
                    <div>
                      <div className="date">
                        {dayjs(item.date).format("DD")}
                      </div>
                      <div className="d-string">
                        <span className="common-heading">
                          {dayjs(item.date).format("dddd")}
                        </span>
                        <span className="month">{item.date}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="ap-empty">There are no dates available</div>
          )}
          <br />
          {loader2 ? (
            <Loader />
          ) : availableTimes.length > 0 ? (
            <div className="available-times">
              <p className="common-heading">
                {availableTimes.length} Times Available on{" "}
                {dayjs(selectedDate).format("dddd, MMM D")}th
              </p>
              <div className="ap-row">
                {availableTimes.map((item, i) => (
                  <div
                    className={`time-item ${
                      item.time === selectedTime ? "time-selected" : ""
                    }`}
                    onClick={() => handleDate(item.time)}
                    key={i}
                  >
                    <div className="common-heading">
                      {dayjs(item.time).tz(state.timezone).format("hh:mm A")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="ap-empty">
              There are no available time of selected date
            </div>
          )}
        </>
      )}
      <br />

      <div className="ap-row">
        <div id="btn1">
          <Button text="Confirm" onClick={() => onClick(70)} />
        </div>
      </div>
    </>
  );
}
