import React from "react";

export default function Input({ type, value, onChange, name, placeholder }) {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      className="ap-input"
    />
  );
}
